
.container{
  color:white;
  display:flex;
  flex-direction: column;
  height:600px;
  width:415px;
  background-color:#181818;
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 15px, rgb(255 255 255 / 15%) 0px 0px 3px 1px;
  border-radius: 5px;
  margin-bottom:15px;
  margin-top:20px;
}

.pictitle{
  margin-left:1rem;
  margin-top:1rem;
  margin-bottom:1rem;


  display:flex;
  flex-direction:row;
  justify-content: space-between;
 
}

.pic{
  margin-top:0.5rem;
  margin-left:0.5rem;
  border-radius: 5px;
  width: 46%;
  height: 100%;
  min-height:173px;
  
}

.title{
  flex-grow: 1;
  margin-top:1.2rem;
}

.tt{
  text-align: center;
  font-size: 1.5rem;

}


.links{
  display:flex;
  margin-left:1.3rem;
 
    
}

.link{
  background-color:#1d1d1d;
  font-size:0.8rem;
  padding:10px;
  border-radius:5px;
  margin-right:0.5rem;
  box-shadow: rgba(255,255,255, 0.15) 1.95px 1.95px 2.6px;
}


.link:hover {
  background-color:#BB595F;
 }

 .paragcont{
   display:flex;
   flex-direction:column;
   margin-left:1rem;
   margin-top:1rem;


 }


 .tech{

  display:flex;
  flex-direction:column;
  margin-left:1rem;
  margin-top:1rem;
  margin-right:2rem;


 }

 .paratitle{
   font-size:1.2rem;
   margin-bottom:0;
 }

 .techtypes{
   justify-content: space-around;
   display:flex;
   flex-direction: row;
   

 }

 .techtitle{
  font-size:1.2rem;
  text-align: center;
  margin-bottom:0;

}

.techicon{
  background-color:#BB595F;
  padding:10px;
  border-radius:5px;

}


a{
  text-decoration:none;
  color:white;
}