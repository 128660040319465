.container{
  margin-top:5%;
  height:80%;
  width:80%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  justify-items:center;
  margin-left:200px;


}

.title{

  color:#BB595F;
  font-size:4rem;
  margin-bottom:5%;

}

.sec{
  display:flex;
}

.text{
  flex-grow: 0.5;
  color:white;
  font-size: 2.5rem;

}

.error{
  color:#BB595F;

}

.formgroup{
  display:flex;
  flex-direction: column;
  align-items:flex-start;
  width:fit-content;
  margin-right:15px;

}





input{
  margin-top:6px;
  min-width:18em;
  height:37px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  background-color:#f3f3f3;
  border:0;
  border-radius: 4px;
  margin-bottom: 31px;
  transition: all 250ms ease-in-out;




}




input :focus{
  outline:none;
  box-shadow:0 0 12px 0.8px #BB595F;
}


textarea{

  margin-top:6px;
  min-width:18em;
  height:100px;
  width:83%;
  padding: 0px 10px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  background-color:#f3f3f3;
  border:0;
  border-radius: 4px;
  margin-bottom: 31px;
  transition: all 250ms ease-in-out;

}







.figure{
  flex-grow: 1;
  flex-direction:row;
 
  align-items:center;
  justify-content: center;
  justify-items:center;
  color:#BB595F;

}


@media only screen and (max-width: 450px) {
  /* For phones */
  .container{
    margin-top:140px;
    align-items:center;
    justify-items:center;


    flex-direction:column;
    margin-left:35px;
    


  }
  .title{

    font-size:2.5rem;
  
  }



  .figure{
    margin-left:0;
  }

  .text{
    margin-top:140px;
    font-size: 1.5rem;
    margin-left:20px;
    font-size:1.3rem;
  }


  input{
    min-width:7em;
  }
  textarea{
    min-width:7em;
  }

  .btn{
    min-width:7em;
  }

  
}



.btn{
  min-width:350px;
  height:50%;
  font-size: 30px;
  padding: 5px 20px;
  border: 0;
  background-color:#BB595F;
  color: #fff;
  border-radius: 6px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}

.btn:focus {
  outline: none;
}



.btn:hover {
  }