.container{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-around;
  justify-items:center;
  margin-left:150px;
  margin-right:100px;
  flex-wrap: wrap;

}


.layout{
  flex-grow:1;
  
}



@media only screen and (max-width: 450px) {
  /* For phones */
  .container{
    margin-top:40px;
    margin-left:5px;
  }

  .layout{

    margin-left:10px;
    margin-right:10px;
    
  }

  
}



