.container{
  height:90%;
  width:80%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  justify-items:center;
  margin-left:200px;


}


.text{
  flex-grow: 0.5;
  color:white;
  
}

.paragraph{
  display:flex;

font-size:1.1rem;
max-width:500px;
margin-top:5px;
 


}
.title{

  color:#BB595F;
  font-size:4rem;
  margin-bottom:5%;

}




.figure{
  flex-grow: 1;
  flex-direction:row;
 
  align-items:center;
  justify-content: center;
  justify-items:center;
  color:#BB595F;

}

.icon{
  background-color:#BB595F;
  padding:10px;
  border-radius:5px;
  margin-left:10px;

}

.skillicon{
  display:flex;
  
}

@media only screen and (max-width: 450px) {
  /* For phones */
  .container{
    margin-top:100px;
    align-items:center;
    justify-items:center;
    flex-direction:column;
    margin-left:0;
    


  }
  .figure{
    margin-left:0;
  }

  .text{
    margin-top:80px;
    margin-left:20px;
    font-size:1.3rem;
  }

  .icon{
    font-size:0.9rem;
  }

  .title{

    font-size:2.5rem;
  
  }

  
}