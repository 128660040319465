.container{
  height:90%;
  width:80%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  justify-items:center;
  margin-left:200px;


}


.text{
  flex-grow: 0.5;
  color:white;
  font-size: 2.5rem;

}

.firstrow{
  display:flex;
}

.secondrow{
  display:flex;
}

.thirdrow{
  display:flex;


}

.fourthrow{
  display:flex;


}




.figure{
  flex-grow: 1;
  flex-direction:row;
 
  align-items:center;
  justify-content: center;
  justify-items:center;
  color:#BB595F;
}


@media only screen and (max-width: 450px) {
  /* For phones */
  .container{
    align-items:center;
    justify-items:center;


    flex-direction:column;
    margin-left:0;
    


  }
  .figure{
    margin-left:0;
    margin-right:0;
  }

  .text{
    margin-top:80px;
    font-size: 1.5rem;
    margin-left:20px;
    font-size:1.3rem;
  }

  
}