html,body,#root{
  background-color: #1D1D1D;
  height:100%;
  width:100%;
  margin:0;
  padding:0;
  cursor: context-menu;
}


.page{
  height:100%;
  width:100%;
  display:flex;
}



