.sidemenu{

  position:fixed;
  left:0;
  top:0;
  height:100%;
  width:4%;
  color:white;
  background-color: #181818;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  font-size:1.5rem;


}
.logo{
  transform:rotate(-15deg);
  height:70px;
  margin-bottom:60px;
  font-family: 'Reggae One', cursive;
  font-size:3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:#BB595F;
  

}


.menu{

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;


}



.contact{

  flex-grow:0.3;
   margin-top:60px;
  height:70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;



}

.icon{
  color:#9B9B9B;




}
.selected{
  color:#BB595F;

}


.icons{

  margin-bottom:10px;

font-size:1rem;
color:#9B9B9B;

  
}




.icons:hover{
  color:#BB595F;

}



.detail{
  color:#BB595F;
font-size:1.3rem;
margin:0;

}















@media screen and (max-width: 450px) {
  /* For phones */
  .sidemenu{
    flex-direction: row;
    height:4%;
    width:100%;
    font-size: 1rem;


  }

  .contact{
    
    flex-direction: row;
    margin-top:0px;
    height:auto;


  }
  .menu{
    flex-direction: row;
  }
  .logo{
    margin-bottom:0;
    height:auto;
    font-size: 1.2rem;
  }


  
}

.selected{
  color:#BB595F;
  text-decoration: none;



}
.link{
  text-decoration: none;
  color:#9B9B9B;


}


.link:hover{
  color:#BB595F;

}