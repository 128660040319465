.container{
  height:90%;
  width:80%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  justify-items:center;
  margin-left:200px;


}

.paragraph{

  margin-bottom:5%;
  max-width:500px;

}


.text{
  flex-grow: 0.5;
  color:white;
  font-size: 1.2rem;

}

.firstrow{
  display:flex;
}

.secondrow{
  display:flex;
}

.thirdrow{
  display:flex;


}

.fourthrow{
  display:flex;


}




.figure{
  flex-grow: 1;
  flex-direction:row;
 
  align-items:center;
  justify-content: center;
  justify-items:center;
  color:#BB595F;

}


.title{

  color:#BB595F;
  font-size:4rem;
  margin-bottom:3%;

}


@media only screen and (max-width: 450px) {
  /* For phones */
  .container{
    margin-top:140px;
    align-items:center;
    justify-items:center;
    flex-direction:column;
    margin-left:0;
    


  }

  .paragraph{
    font-size:0.9rem;


  }
  .figure{
    margin-left:0;
  }

  .text{
    margin-top:80px;
    margin-left:20px;
    font-size:1.3rem;
  }


  .title{

    font-size:2.5rem;
  
  }

  

  
}







