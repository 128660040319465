.logo{
  text-align: center;
  font-size:24rem;
  font-family: 'Reggae One', cursive;
}





@media only screen and (max-width: 450px) {
  /* For phones */
  .logo{
    font-size: 15rem;
    margin-right:30px;
  }
 

}